var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"bg-color-theme cashbook-main-create mt-2"},[_c('v-card',[_c('v-card-text',{staticClass:"py-8"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"lazy-validation":"","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',{staticClass:"px-7",attrs:{"align":"center"}},[(_vm.currentUserShop.user_role !== 'S' && !_vm.editMode)?[_c('p',{staticClass:"cls-head"},[_vm._v(" 報告者："+_vm._s(((_vm.user.surname) + " " + (_vm.user.name)))+" ")])]:(_vm.editMode)?[_c('p',{staticClass:"cls-head"},[_vm._v(" 報告者： "),(_vm.getAccountsPayable.user_shop)?[_vm._v(" "+_vm._s(((_vm.getAccountsPayable.user_shop.user.surname) + " " + (_vm.getAccountsPayable.user_shop.user.name)))+" ")]:[_vm._v(" "+_vm._s(("" + (_vm.getAccountsPayable.shared_account.name)))+" ")]],2)]:[_c('validation-provider',{attrs:{"rules":"","name":"user_shop_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-col',{staticClass:"d-inline-block",attrs:{"cols":"3"}},[_vm._v(" 報告者 ")]),_c('v-col',{staticClass:"d-inline-block flex-grow-1 flex-shrink-0",attrs:{"cols":"9"}},[_c('v-select',{staticClass:"font-12px",attrs:{"solo":"","dense":"","background-color":"#EAEDF0","error-messages":errors,"items":_vm.currentShop.shared_account.shared_accounts,"item-text":"name","item-value":"id","placeholder":"山田 太郎","no-data-text":"メンバーが追加されていません"},model:{value:(_vm.shared_account_id),callback:function ($$v) {_vm.shared_account_id=$$v},expression:"shared_account_id"}})],1)]}}],null,true)})]],2),_vm._l((_vm.fields),function(field){return _c('v-row',{key:field.name,staticClass:"ma-0 px-7"},[(field.type === 'currency')?[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-label',[_vm._v(_vm._s(field.label)+" ")]),_c('InputCurrency',{staticClass:"cashbook-field",attrs:{"prefix":field.prefix,"placeholder":field.placeholder,"background-color":"#EAEDF0","dense":"","solo":"","error-messages":errors,"autocomplete":"chrome-off","hint":field.hint,"persistent-hint":"","type":"number","max":10000000},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-col')]},proxy:true}],null,true),model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", _vm._n($$v))},expression:"field.value"}})]}}],null,true)})],1)]:(field.type == 'datepicker')?[_c('v-col',{staticClass:"px-0 cls-date",attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-label',[_vm._v(_vm._s(field.label)+" "),(field.rules.includes('required'))?_c('span',{staticClass:"required-text"},[_vm._v(" 必須 ")]):_vm._e()]),_c('DatePicker',{attrs:{"dense":"","solo":"","background-color":"#EAEDF0","hide-details":errors.length <= 0,"separator":'-',"field":field,"errors":errors,"disabled":field.disabled,"min":_vm.min,"max":_vm.max},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-col',{attrs:{"cols":"6"}})]},proxy:true}],null,true),model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1)]:(field.type == 'radio')?[_c('v-radio-group',{staticClass:"font-14px cls-radio",attrs:{"row":"","dense":""},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},[(field.label)?_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(field.label))]):_vm._e(),_vm._l((field.children),function(child,i){return _c('v-radio',{key:i,attrs:{"value":child.value,"color":"#602BD0","label":child.label}})})],2)]:(field.type == 'dropdown')?[_c('v-col',{staticClass:"pa-0",class:field.col_class,attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-label',[_vm._v(_vm._s(field.label)+" ")]),_c('v-select',{staticClass:"cashbook-select",class:field.class,attrs:{"solo":"","dense":"","prefix":field.prefix,"background-color":"#EAEDF0","items":_vm.getItemsList(field),"error-messages":errors,"item-text":field.item_text,"item-value":field.item_value,"placeholder":field.placeholder,"return-object":field.name === 'payer'},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-center full-width"},[_vm._v(" "+_vm._s(item.text || item)+" ")])]}}],null,true),model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1)]:(field.type == 'textarea')?[_c('v-col',{staticClass:"pa-0 cls-txt",class:field.col_class,attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-expansion-panels',{attrs:{"flat":"","accordion":""}},[_c('v-expansion-panel',{attrs:{"aria-expanded":"true"}},[_c('v-expansion-panel-header',{staticClass:"px-0 font-14px"},[_vm._v(" "+_vm._s(field.label)+" ")]),_c('v-expansion-panel-content',{staticClass:"expansion-content"},[_c('v-textarea',{staticClass:"font-15px",class:field.class,attrs:{"placeholder":field.placeholder,"background-color":"#EAEDF0","dense":"","solo":"","error-messages":errors,"rows":"5","auto-grow":""},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)],1)],1)]}}],null,true)})],1)]:_vm._e()],2)}),_c('v-row',{staticClass:"ma-0 mt-3"},[_c('v-col',{staticClass:"px-0 pt-0 pb-0 d-flex justify-space-between",attrs:{"cols":"12","md":"12"}},[(_vm.editMode)?_c('v-btn',{staticClass:"primary--text border-primary",attrs:{"type":"button","color":"white","min-width":"146"},on:{"click":function($event){$event.stopPropagation();return _vm.showModal.apply(null, arguments)}}},[_vm._v(" 削除する ")]):_vm._e(),(!_vm.editMode)?_c('v-btn',{staticClass:"primary--text border-primary",attrs:{"type":"button","color":"white","min-width":"146"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.go(-1)}}},[_vm._v(" キャンセル ")]):_vm._e(),_c('v-btn',{attrs:{"type":"submit","color":"primary","min-width":"146","loading":_vm.loading,"disabled":invalid}},[_vm._v(" "+_vm._s(_vm.editMode ? "保存する" : "登録する")+" ")])],1)],1)],2)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }