<template>
  <v-container class="bg-color-theme cashbook-main-create mt-2">
    <v-card>
      <v-card-text class="py-8">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="submit" lazy-validation autocomplete="off">
            <v-row align="center" class="px-7">
              <template v-if="currentUserShop.user_role !== 'S' && !editMode">
                <p class="cls-head">
                  報告者：{{ `${user.surname} ${user.name}` }}
                </p>
              </template>
              <template v-else-if="editMode">
                <p class="cls-head">
                  報告者：
                  <template v-if="getAccountsPayable.user_shop">
                    {{
                      `${getAccountsPayable.user_shop.user.surname} ${getAccountsPayable.user_shop.user.name}`
                    }}
                  </template>
                  <template v-else>
                    {{ `${getAccountsPayable.shared_account.name}` }}
                  </template>
                </p>
              </template>
              <template v-else>
                <validation-provider
                  v-slot="{ errors }"
                  rules=""
                  name="user_shop_id"
                >
                  <v-col cols="3" class="d-inline-block">
                    報告者
                  </v-col>
                  <v-col
                    cols="9"
                    class="d-inline-block flex-grow-1 flex-shrink-0"
                  >
                    <v-select
                      solo
                      dense
                      class="font-12px"
                      background-color="#EAEDF0"
                      :error-messages="errors"
                      :items="currentShop.shared_account.shared_accounts"
                      item-text="name"
                      item-value="id"
                      placeholder="山田 太郎"
                      v-model="shared_account_id"
                      no-data-text="メンバーが追加されていません"
                    ></v-select>
                  </v-col>
                </validation-provider>
              </template>
            </v-row>

            <v-row v-for="field in fields" :key="field.name" class="ma-0 px-7">
              <template v-if="field.type === 'currency'">
                <v-col
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="pa-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-label>{{ field.label }} </v-label>
                    <InputCurrency
                      :prefix="field.prefix"
                      :placeholder="field.placeholder"
                      background-color="#EAEDF0"
                      dense
                      solo
                      v-model.number="field.value"
                      :error-messages="errors"
                      autocomplete="chrome-off"
                      :hint="field.hint"
                      persistent-hint
                      type="number"
                      class="cashbook-field"
                      :max="10000000"
                    >
                      <template v-slot:append>
                        <v-col></v-col>
                      </template>
                    </InputCurrency>
                  </validation-provider>
                </v-col>
              </template>

              <template v-else-if="field.type == 'datepicker'">
                <v-col
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="px-0 cls-date"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-label
                      >{{ field.label }}
                      <span
                        v-if="field.rules.includes('required')"
                        class="required-text"
                      >
                        必須
                      </span>
                    </v-label>
                    <DatePicker
                      dense
                      solo
                      background-color="#EAEDF0"
                      :hide-details="errors.length <= 0"
                      v-model="field.value"
                      :separator="'-'"
                      :field="field"
                      :errors="errors"
                      :disabled="field.disabled"
                      :min="min"
                      :max="max"
                    >
                      <template v-slot:append>
                        <v-col cols="6"></v-col>
                      </template>
                    </DatePicker>
                  </validation-provider>
                </v-col>
              </template>

              <template v-else-if="field.type == 'radio'">
                <v-radio-group
                  class="font-14px cls-radio"
                  row
                  dense
                  v-model="field.value"
                >
                  <span v-if="field.label" class="mr-2">{{ field.label }}</span>
                  <v-radio
                    v-for="(child, i) in field.children"
                    :key="i"
                    :value="child.value"
                    color="#602BD0"
                    :label="child.label"
                  >
                  </v-radio>
                </v-radio-group>
              </template>

              <template v-else-if="field.type == 'dropdown'">
                <v-col
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="pa-0"
                  :class="field.col_class"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-label>{{ field.label }} </v-label>
                    <v-select
                      solo
                      dense
                      :prefix="field.prefix"
                      class="cashbook-select"
                      background-color="#EAEDF0"
                      :class="field.class"
                      :items="getItemsList(field)"
                      v-model="field.value"
                      :error-messages="errors"
                      :item-text="field.item_text"
                      :item-value="field.item_value"
                      :placeholder="field.placeholder"
                      :return-object="field.name === 'payer'"
                    >
                      <template v-slot:selection="{ item }">
                        <span class="text-center full-width">
                          {{ item.text || item }}
                        </span>
                      </template>
                    </v-select>
                  </validation-provider>
                </v-col>
              </template>

              <template v-else-if="field.type == 'textarea'">
                <v-col
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="pa-0 cls-txt"
                  :class="field.col_class"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-expansion-panels flat accordion>
                      <v-expansion-panel aria-expanded="true">
                        <v-expansion-panel-header class="px-0 font-14px">
                          {{ field.label }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="expansion-content">
                          <v-textarea
                            :placeholder="field.placeholder"
                            background-color="#EAEDF0"
                            dense
                            solo
                            v-model="field.value"
                            :error-messages="errors"
                            :class="field.class"
                            class="font-15px"
                            rows="5"
                            auto-grow
                          ></v-textarea>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </validation-provider>
                </v-col>
              </template>
            </v-row>

            <v-row class="ma-0 mt-3">
              <v-col
                class="px-0 pt-0 pb-0 d-flex justify-space-between"
                cols="12"
                md="12"
              >
                <v-btn
                  type="button"
                  color="white"
                  class="primary--text border-primary"
                  min-width="146"
                  @click.stop="showModal"
                  v-if="editMode"
                >
                  削除する
                </v-btn>
                <v-btn
                  type="button"
                  color="white"
                  class="primary--text border-primary"
                  min-width="146"
                  @click.stop="$router.go(-1)"
                  v-if="!editMode"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  type="submit"
                  color="primary"
                  min-width="146"
                  :loading="loading"
                  :disabled="invalid"
                >
                  {{ editMode ? "保存する" : "登録する" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DatePicker from "@/components/ui/DatePicker";
import InputCurrency from "@/components/ui/InputCurrency";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";

export default {
  name: "AccountsCreateEdit",
  components: { InputCurrency, DatePicker },
  computed: {
    ...mapGetters([
      "user",
      "getFirstShopObject",
      "getSuppliers",
      "getAccountsPayable"
    ]),
    currentShop() {
      return this.getFirstShopObject;
    },
    currentUserShop() {
      return this.currentShop.user_shop.find(
        user => user.user_id == this.user.id
      );
    },
    loading() {
      return this.$store.getters.getApiProcessingStatus;
    },
    suppliers() {
      return this.getSuppliers.map(supplier => {
        return {
          value: supplier.id,
          text: supplier.display_name
        };
      });
    },
    id() {
      return this.$route.params.id;
    }
  },
  data() {
    return {
      editMode: this.$route.name === "accountsPayableEdit",
      errors: "",
      min: "",
      max: "",
      shared_account_id: null,
      fields: [
        {
          prefix: "発生日",
          name: "accrual_date",
          type: "datepicker",
          placeholder: "2021年 9月 18日",
          value: dayjs(),
          rules: "",
          locale: "ja",
          date_format: "YYYY年 MM月 DD日",
          date: "",
          menu: false,
          class: "cashbook-field",
          disabled: false
        },
        {
          prefix: "取引先",
          name: "partner_id",
          type: "dropdown",
          placeholder: "東京ABC市...",
          value: "",
          rules: "required:取引先"
        },
        {
          prefix: "仕分",
          name: "surveyed_subjects",
          type: "dropdown",
          items: [
            "フード仕入",
            "ドリンク仕入",
            "消耗品費",
            "給料手当",
            "通信費",
            "租税公課",
            "旅費",
            "交通費",
            "会議費",
            "交際費",
            "新聞図書費",
            "福利厚生費",
            "雑費"
          ].map((item, index) => {
            return {
              value: index,
              text: item
            };
          }),
          placeholder: "フード仕入",
          value: "",
          rules: "required:仕分"
        },
        {
          prefix: "入出金額",
          name: "purchase_amount",
          type: "currency",
          placeholder: "￥1,080",
          value: "",
          rules: "required:入出金額|min:1|enter_half_width_amount",
          class: "cashbook-field"
        },
        {
          label: "税区分",
          type: "radio",
          name: "taxonomy",
          value: 2,
          rules: "required:税区分",
          children: [
            {
              label: "8%",
              value: 2
            },
            {
              label: "10%",
              value: 1
            }
          ]
        },
        {
          label: "摘要/メモ",
          name: "notes",
          type: "textarea",
          placeholder: "自由入力",
          value: "",
          rules: ""
        }
      ]
    };
  },
  created() {
    if (this.getFirstShopObject.shared_account) {
      this.fields.forEach(field => {
        if (field.name == "accrual_date") {
          field.disabled = true;
        }
      });
    }
    this.$store.dispatch("SUPPLIER_GET_ALL", { shop_id: this.currentShop.id });
    if (this.editMode) {
      this.$store
        .dispatch("ACCOUNTS_PAYABLE_GET", {
          id: this.currentShop.id,
          accounts_payable_id: this.id
        })
        .then(() => {
          this.fields.forEach(field => {
            field.value = this.getAccountsPayable[field.name];
            if (field.name == "surveyed_subjects") {
              field.value = parseInt(this.getAccountsPayable[field.name]);
            }

            if (field.name == "accrual_date") {
              field.disabled = true;
              field.value = dayjs(this.getAccountsPayable[field.name]).format(
                "YYYY-MM-DD"
              );
            }
          });
        });
    }
    this.min = dayjs()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    this.max = dayjs()
      .endOf("month")
      .format("YYYY-MM-DD");
  },
  methods: {
    showModal() {
      this.$root.confirm
        .open(`この売上・コスト情報を\n削除しますか？`)
        .then(async res => {
          if (res) {
            let shop_id = this.currentShop.id;
            this.$store
              .dispatch("ACCOUNTS_PAYABLE_DELETE", {
                shop_id: shop_id,
                accounts_payable_id: this.id
              })
              .then(() => {
                this.$router.push({ name: "accountsList" });
                this.$store.commit("showAlert", {
                  text: "登録情報を削除しました。",
                  successStatus: "info"
                });
              });
          }
        });
    },

    getItemsList(field) {
      if (field.name === "surveyed_subjects") return field.items;
      else if (field.name === "partner_id") return this.suppliers;
      else return this.payers;
    },

    async submit() {
      this.$refs.observer.validate().then(async success => {
        if (!success) {
          return;
        }

        let params = {};
        let shop_id = this.currentShop.id;

        this.fields.forEach(form => {
          params[form.name] = form.value;
        });
        params.accrual_date = dayjs(
          params.accrual_date,
          "YYYY年 MM月 DD日"
        ).format("YYYY-MM-DD");

        if (this.currentUserShop.user_role === "S" && this.shared_account_id) {
          params.shared_account_id = this.shared_account_id;
        } else {
          params.user_shop_id = this.currentUserShop.id;
        }

        await this.$store.dispatch(
          this.editMode
            ? "ACCOUNTS_PAYABLE_UPDATE"
            : "ACCOUNTS_PAYABLE_REGISTER",
          {
            params: params,
            shop_id: shop_id,
            ...(this.editMode ? { accounts_payable_id: this.id } : {})
          }
        );

        this.$router.push({ name: "accountsList" });
        this.$store.commit("showAlert", {
          text: "編集内容を保存しました。",
          successStatus: "info"
        });
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss"></style>
